import React from 'react'
import { graphql, Link } from 'gatsby'
import InnerLayout from '../components/inner-layout';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from '../components/seo';
import Dump from '../components/dump';
import BlogHeader from '../components/blog-header';

export default ({ data, pageContext }) => {
    const { frontmatter, body } = data.mdx;
    const { previous, next } = pageContext;
    return (
        <InnerLayout>
            <SEO title="Blog" />
            <BlogHeader />
            <div className="container">

                <section>
                    <h4>{frontmatter.title}</h4>
                    <span>{frontmatter.author}</span>
                    <p>Posted on {frontmatter.date}</p>
                    <MDXRenderer>{body}</MDXRenderer>
                    {previous === false ? null : (
                        <>
                            {previous && (
                                <Link to={previous.fields.slug}>
                                    <p>{previous.frontmatter.title}</p>
                                </Link>
                            )}
                        </>
                    )}
                    {next === false ? null : (
                        <>
                            {next && (
                                <Link to={next.fields.slug}>
                                    <p>{next.frontmatter.title}</p>
                                </Link>
                            )}
                        </>
                    )}
                </section>
            </div>
        </InnerLayout>
    )
}

export const query = graphql`
query PostBySlug($slug: String!) {
    mdx(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        author
      }
      body
    }
  }  
`;